<!--
 * @Author: your name
 * @Date: 2022-01-13 10:14:53
 * @LastEditTime: 2022-02-07 16:25:35
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \lightning-web\src\components\Dialog\index.vue
-->
<template>
  <div class="transfer-client-owner">
    <el-dialog
      :visible="dialogSign"
      :title="titleType"
      top="15%"
      width="25%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <slot name="dialogContent"></slot>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button @click="dialogEdit" type="primary" size="mini">
          {{ btnName }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script type="text/ecmascript-6">
export default {
  props: {
    /**
     * 弹窗是否显示
     */
    dialogSign: {
      type: Boolean,
      default: false,
    },
    // 按钮操作名称
    btnName: {
      type: String,
      default: "",
    },
    /**
     * 弹窗标题
     */
    titleType: {
      type: String,
      default: "",
    },
  },
  methods: {
    dialogEdit() {
      this.$emit("dialogEdit");
    },
    handleClose() {
      this.$emit("dialoghandleClose");
    },
  },
};
</script>
<style scoped lang="scss">
</style>
